<template>
    <div class="adminssion-info">
        <div class="info-cantainer">
            <div class="patient-info">
                <p class="txt">{{$t("subscribe.submitTime")}}：{{getLocalMinutesTime(admissionInfo.zhuyuanCdt)}}</p>
                <p class="txt">{{$t("subscribe.patient")}}：{{admissionInfo.patientName||'--'}}</p>
                <p class="emtry-hr"></p>
            </div>
            <div class="adminssion-status">
                <p class="status-item">
                    <span class="label">
                        {{$t("subscribe.statusLabel")}}：
                    </span>
                    <span class="value" :class="'val'+admissionInfo.status">
                        {{statusObj[admissionInfo.status]}}
                    </span>
                </p>
                <p class="status-item" v-if="admissionInfo.status == 141004">
                    <span class="label">
                        {{$t("subscribe.cause")}}：
                    </span>
                    <span class="value">
                        {{admissionInfo.rejectReason}}
                    </span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { admissionRead, getAdmissionInfo } from "@/api/index";
import { getLocalMinutesTime } from "@/utils/date"
export default {
    data () {
        return {
            admissionInfo:{},
            getLocalMinutesTime,
            statusObj:{
                "141001":this.$t("subscribe.statusTxt1"),
                "141002":this.$t("subscribe.statusTxt2"),
                "141003":this.$t("subscribe.statusTxt3"),
                "141004":this.$t("subscribe.statusTxt4")
            }
        }
    },
    created () {
        this.getInfoData()
        admissionRead({zhuyuanId:this.$route.query.zhuyuanId})
    },
    methods:{
        getInfoData(){
            const toast = this.$Toast.loading({
                message: this.$t("message.loadingTxt"),
                forbidClick: true,
            });
            getAdmissionInfo({
                zhuyuanId:this.$route.query.zhuyuanId
            }).then(res =>{
                toast.clear();
                if(res.data.errorCode == 0){
                    this.admissionInfo = res.data.data
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.adminssion-info{
    min-height: 100%;
    background-color: #FE9B5E;
    background-image: url("~@/assets/img/admission-info-bannner.png");
    background-repeat: no-repeat;
    background-size:15rem 7.8rem;
    background-position: top;
    padding: 6.8rem 0.2rem 0.6rem 0.2rem;
    box-sizing: border-box;
    .info-cantainer{
        padding: 0.4rem;
        position: relative;
        &::after{
            position: absolute;
            content: ' ';
            top: 0;
            left: 0;
            width: 100%;
            height: 0.8rem;
            background: linear-gradient(0deg, #F65117 0%, #FFBE60 100%);
            border-radius: 0.4rem;
            z-index: 0;
        }
        .patient-info{
            position: relative;
            z-index: 1;
            width: 100%;
            box-sizing: border-box;
            // height: 4.6rem;
            padding: 1.2rem;
            background: #FFF4E4;
            .txt{
                font-size: 0.64rem;
                font-family: PingFang SC;
                font-weight: 400;
                color: #606266;
                &:first-child{
                    margin-bottom: 0.4rem;
                }
            }
            &::after,&::before{
                content: ' ';
                width: 0.8rem;
                height: 0.8rem;
                border-radius: 50%;
                position: absolute;
                bottom: -0.4rem;
                left: -0.4rem;
                background: #FE9B5E;
            }
            &::before{
                left: auto;
                right: -0.4rem;
            }
        }

    }
    .adminssion-status{
        background: #FFFFFF;
        border-radius: 0px 0px 0.52rem 0.52rem;
        box-sizing: border-box;
        padding: 1.6rem 1.2rem 1.3rem 1.2rem;
        .status-item{
            display: flex;
            
            font-family: PingFang SC;
            font-weight: 400;
            align-items: center;
            color: #606266;
            .label{
                flex: 0 0 auto;
                font-size: 0.64rem;
            }
            .value{
                flex: 1;
                font-size: 0.96rem;
            }
            .val141001{
                color: #FFC00F;
            }
            .val141002{
                color: #49A7FF;
            }
            .val141003{
                color: #909399;
            }
            .val141004{
                color: #FF3535;
            }
        }
    }
}
</style>